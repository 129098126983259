@import "./utils/reset";

@font-face {
  font-family: "Zurich";
  src: local("ZurichBold"),
    url("../fonts/Zurich/Zurichbt_bold.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: normal;

}

@font-face {
  font-family: "Zurich";
  src: local("Zurich"),
    url("../fonts/Zurich/Zurichbt_bolditalic.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Zurich";
  src: local("Zurich"),
    url("../fonts/Zurich/Zurichbt_italic.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: italic;

}

@font-face {
  font-family: "Zurich";
  src: local("Zurich"),
    url("../fonts/Zurich/Zurichbt_roman.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

// @font-face {
//   font-family: "Corbel";
//   src: local("Corbel"),
//     url("../fonts/Corbel/Corbel.ttf") format("truetype");
//   font-weight: normal;
// }
@font-face {
  font-family: "Corbel";
  src: local("Corbel"),
    url("../fonts/Corbel/Corbel-Bold.woff2") format("woff2");
  // font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Corbel";
  src: local("Corbel"),
    url("../fonts/Corbel/Corbel-BoldItalic.woff2") format("woff2");
  // font-weight: bold;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Corbel";
  src: local("Corbel"),
    url("../fonts/Corbel/Corbel-Italic.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: italic;

}

@font-face {
  font-family: "Corbel";
  src: local("Corbel"),
    url("../fonts/Corbel/Corbel.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

html {
  font-size: 10px;
}

body {
  font-family: "Corbel";
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.content{
  // margin-top: clamp(2.4rem, 2vw, 3rem);
  p, b{
    font-family: "Corbel";
    font-style: normal;
    font-size: clamp(1.4rem, 2vw, 1.8rem);
  }
}